import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import { hideModal } from '../../../modules/modal/modal';
import { bindActionCreators } from 'redux';
import {
  seatingChartActions,
  seatingChartSelectors,
} from 'modules/seatingCharts/seatingCharts';
import { ticketSelectors, ticketActions } from 'modules/tickets/tickets';
import { showModal } from '../../../modules/modal/modal';
import SeatingChart from '../../../components/SeatingChart/SeatingChart';

const SeatingChartModal = ({
  seatingUser,
  performanceId,
  purchaseId,
  quantity,
  order,
  showModal,
  tickets,
  purchase,
  getSeatingUser,
  fetchTicketsForPage,
  selectSeatforPurchase,
  unselectSeatforPurchase,
}) => {
  useEffect(() => {
    fetchTicketsForPage({
      performanceId: performanceId,
      page: 1,
    });
  }, [fetchTicketsForPage]);

  useEffect(() => {
    if (!seatingUser) {
      getSeatingUser();
    }
  }, [seatingUser]);

  const handleSeatingChartModalClose = () => {
    showModal('TRANSFER_MODAL', {
      orderId: order.id,
    });
  };

  const handleSeatSelected = (selectedTicket, selectedSeat) => {
    selectSeatforPurchase({
      purchaseId: purchaseId,
      selectedTicket: selectedTicket,
      selectedSeat: selectedSeat,
    });
  };

  const handleSeatUnSelected = (selectedTicket, selectedSeat) => {
    unselectSeatforPurchase({
      purchaseId: purchaseId,
      selectedTicket: selectedTicket,
      selectedSeat: selectedSeat,
    });
  };

  return (
    <Modal>
      <SeatingChart
        publicKey={seatingUser && seatingUser.publicKey}
        performanceId={performanceId}
        quantity={quantity}
        onSeatingChartModalClose={handleSeatingChartModalClose}
        tickets={tickets}
        seatSelectedHandler={handleSeatSelected}
        seatUnSelectedHandler={handleSeatUnSelected}
        currentSeats={purchase.seats}
        selectedSeats={{ seats: [] }}
      />
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const performanceId = parseInt(ownProps.performanceId, 10);

  const purchase = ownProps.lineItems.tickets.find(
    (p) => p.purchaseId === ownProps.purchaseId
  );
  const tickets = ticketSelectors.getForPerformanceIdAndPage(
    state,
    performanceId,
    1
  );

  return {
    performanceId,
    seatingUser: seatingChartSelectors.getSeatingUser(state),
    purchase: purchase,
    tickets: tickets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        ...ticketActions,
        ...seatingChartActions,
        hideModal,
        showModal,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatingChartModal);
