import React, { Fragment } from 'react';
import {
  aggregatorTemplates as tpl,
  numberFormat
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';
import ReportSection from '../../../components/ReportSection/ReportSection';

const SettlementReport = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const usFmtPct = numberFormat({
    digitsAfterDecimal: 1,
    scaler: 100,
    suffix: '%'
  });
  const fmt = numberFormat({ prefix: '£' });
  return (
    <Fragment>
      <ReportSection>
        <PivotTable
          title={`${title} - Summary`}
          exportFilename={`${exportFilename}-summary`}
          data={data}
          aggregators={{
            'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
            Comps: () => tpl.sum(usFmtInt)(['Comp']),
            Gross: () => tpl.sum(fmt)(['Face Value Gross']),
            Tax: () => tpl.sum(fmt)(['Face Value Tax']),
            Net: () => tpl.sum(fmt)(['Face Value Net'])
          }}
          rows={['Channel']}
          derivedAttributes={{
            Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
            Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0),
            Channel: record =>
              record['Seller'] === 'Direct' ? 'Direct Sales' : 'Agency Sales'
          }}
        />
      </ReportSection>
      <ReportSection>
        <PivotTable
          title={`${title} - Agent Commissions Breakdown`}
          exportFilename={`${exportFilename}-agent-commissions-breakdown`}
          data={data}
          aggregators={{
            'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
            Comps: () => tpl.sum(usFmtInt)(['Comp']),
            Gross: () => tpl.sum(fmt)(['Face Value Gross']),
            'Gross Commission': () => tpl.sum(fmt)(['Inside Fee Gross']),
            Tax: () => tpl.sum(fmt)(['Inside Fee Tax']),
            'Net Commission': () => tpl.sum(fmt)(['Inside Fee Net'])
          }}
          derivedAttributes={{
            Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
            Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0),
            'Commission Rate': record =>
              record['Inside Fee Fixed'] > 0
                ? record['Inside Fee Percentage'] > 0
                  ? `${fmt(record['Inside Fee Fixed'])} + ${usFmtPct(
                      record['Inside Fee Percentage']
                    )}`
                  : fmt(record['Inside Fee Fixed'])
                : usFmtPct(record['Inside Fee Percentage'])
          }}
          valueFilter={{
            Seller: { Direct: false }
          }}
          rows={['Seller', 'Commission Rate']}
        />
      </ReportSection>
    </Fragment>
  );
};

export default SettlementReport;
