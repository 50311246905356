import React from "react";

import {
  aggregatorTemplates as tpl,
  numberFormat,
  sortAs,
} from "../../../components/PivotTable/Utilities";
import PivotTable from "../../../components/PivotTable/PivotTable";

const Transactions = ({ data, title, exportFilename }) => {
  const fmt = numberFormat({ prefix: "£" });
  return (
    <PivotTable
      title={title}
      exportFilename={exportFilename}
      data={data}
      aggregators={{
        Gross: () => tpl.sum(fmt)(["Gross"]),
        Tax: () => tpl.sum(fmt)(["Tax"]),
        Net: () => tpl.sum(fmt)(["Net"]),
        "Inside Fee Gross": () => tpl.sum(fmt)(["Inside Fee Gross"]),
        "Inside Fee Tax": () => tpl.sum(fmt)(["Inside Fee Tax"]),
        "Inside Fee Net": () => tpl.sum(fmt)(["Inside Fee Net"]),
        "Lineup Fee Gross": () => tpl.sum(fmt)(["Lineup Fee Gross"]),
        "Lineup Fee Tax": () => tpl.sum(fmt)(["Lineup Fee Tax"]),
        "Lineup Fee Net": () => tpl.sum(fmt)(["Lineup Fee Net"]),
        "Payment Fee": () => tpl.sum(fmt)(["Payment Fee"]),
      }}
      derivedAttributes={{
        Sold: (record) => (record["Gross"] !== 0 ? record["Quantity"] : 0),
        Comp: (record) => (record["Gross"] === 0 ? record["Quantity"] : 0),
        Channel: (record) =>
          record["Seller"] === "Direct" ? "Direct Sales" : "Agency Sales",
      }}
      rows={[
        "Payment Source",
        "Transaction Type",
        "Item Type",
        "Receipt Title",
      ]}
      sorters={{
        "Transaction Type": sortAs(["Sale", "Refund"]),
        "Item Type": sortAs(["Ticket", "Product"]),
      }}
    />
  );
};

export default Transactions;
