import React, { Component } from "react";
import MainContent, {
  Title,
  SubTitle,
  ContentContainer,
  LineUpHelmet,
} from "../../components/MainContent/MainContent";
import Lozenge from "../../components/Lozenge/Lozenge";

// Grommet
import { Box, Grid, ResponsiveContext } from "grommet";
import {
  BarChart,
  LineChart,
  Ticket,
  Calendar,
  Tag,
  CreditCard,
  History,
  Tasks,
  Directions,
  Archive,
  Money,
} from "grommet-icons";

const columns = {
  small: ["auto"],
  medium: ["31%", "31%", "31%"],
  large: ["31%", "31%", "31%"],
};

const rows = {
  small: ["auto", "auto", "auto"],
  medium: ["auto"],
  large: ["auto"],
};

const fixedGridAreas = {
  small: [
    { name: "sales", start: [0, 0], end: [0, 0] },
    { name: "financials", start: [0, 1], end: [0, 1] },
    { name: "analytics", start: [0, 2], end: [0, 2] },
  ],
  medium: [
    { name: "sales", start: [0, 0], end: [0, 0] },
    { name: "financials", start: [1, 0], end: [1, 0] },
    { name: "analytics", start: [2, 0], end: [2, 0] },
  ],
  large: [
    { name: "sales", start: [0, 0], end: [0, 0] },
    { name: "financials", start: [1, 0], end: [1, 0] },
    { name: "analytics", start: [2, 0], end: [2, 0] },
  ],
};

const Responsive = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // Take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // Also if areas is a simple array not an object of arrays for
      // different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
        >
          {children}
        </Grid>
      );
    }}
  </ResponsiveContext.Consumer>
);

class Reports extends Component {
  render() {
    return (
      <>
        <LineUpHelmet
          title="Reports"
          description="Your Line-Up event sales reports"
        />
        <MainContent>
          <Title>Reports</Title>
          <Responsive
            rows={rows}
            columns={columns}
            gap="3.5%"
            areas={fixedGridAreas}
          >
            <Box margin={{ vertical: "medium" }} gridArea="sales">
              <Box margin={{ bottom: "medium" }}>
                <SubTitle title="Sales" />
              </Box>
              <ContentContainer>
                <Box gap="small">
                  <Lozenge
                    label="Wraps Report"
                    icon={<BarChart size="medium" />}
                    href="/report/wrap"
                  />
                  <Lozenge
                    label="Settlement Report"
                    icon={<LineChart size="medium" />}
                    href="/report/settlement"
                  />
                  <Lozenge
                    label="Sales by Ticket Price"
                    icon={<Ticket size="medium" />}
                    href="/report/sales-by-ticket-price"
                  />
                  <Lozenge
                    label="Sales by Performance Date"
                    icon={<Calendar size="medium" />}
                    href="/report/sales-by-performance-date"
                  />
                  <Lozenge
                    label="Discounts"
                    icon={<Tag size="medium" />}
                    href="/report/discounts"
                  />
                  <Lozenge
                    label="Inventory"
                    icon={<Tasks size="medium" />}
                    href="/report/inventory"
                  />
                  <Lozenge
                    label="Agent Sales"
                    icon={<Directions size="medium" />}
                    href="/report/agent-sales"
                  />
                </Box>
              </ContentContainer>
            </Box>
            <Box margin={{ vertical: "medium" }} gridArea="financials">
              <Box margin={{ bottom: "medium" }}>
                <SubTitle title="Financials" />
              </Box>
              <ContentContainer>
                <Box gap="small">
                  <Lozenge
                    label="Payments Report"
                    icon={<CreditCard size="medium" />}
                    href="/report/payments"
                  />
                  <Lozenge
                    label="Advance Vs Matured"
                    icon={<History size="medium" />}
                    href="/report/advance"
                  />
                  <Lozenge
                    label="All Transactions"
                    icon={<Archive size="medium" />}
                    href="/report/all-transactions"
                  />
                  <Lozenge
                    label="Revenue by Source"
                    icon={<Money size="medium" />}
                    href="/report/payments-by-source"
                  />
                </Box>
              </ContentContainer>
            </Box>
            <Box margin={{ vertical: "medium" }} gridArea="analytics">
              <Box margin={{ bottom: "medium" }}>
                <SubTitle title="Analytics" />
              </Box>
              <ContentContainer>
                <Box gap="small">
                  <Lozenge
                    label="Sales by Transaction Day"
                    icon={<Calendar size="medium" />}
                    href="/report/sales-by-transaction-day"
                  />
                  <Lozenge
                    label="Discount Usage"
                    icon={<Tag size="medium" />}
                    href="/report/discount-usage"
                  />
                </Box>
              </ContentContainer>
            </Box>
          </Responsive>
        </MainContent>
      </>
    );
  }
}

export default Reports;
